import { JSX } from "react/jsx-runtime";
import { SegmentInfo } from './Type/SegmentInfo';
import { useSelector } from "react-redux";
import { useReplaceStore } from "./Actions/changeStore";
import { useEffect, useState } from "react";

const backendURL = "https://mtv.internal.dev.eks-nprd.cloud.bpifrance.fr/backend";

// this file gets the config from the backend and generates the wheel

function genSegment(seg_number: number, seg_color: string, total: number, leftX: number, rightX: number) {
    // generates a CSSProperties elements to add variables to our style section of the HTML elements
    return { 
        "--i": seg_number,
        "--clr": seg_color,
        "--n": total,
        "--leftX": leftX.toString() + "%",
        "--rightX": rightX.toString() + "%"} as React.CSSProperties;
}

function Segment() {
    const [body, setBody] = useState([] as SegmentInfo[]);

    // fetch segments from the db
    useEffect(() => {
        async function getSegments() {
            try {
                // api call to get the data
                const segRes = await fetch(backendURL + "/getSegments", {
                    method: "GET",
                    headers: {
                        'Content-type': "application/JSON",
                        'Origin': "https://rdlfbingo.dev.mtv-nprd.cloud.bpifrance.fr"
                    }
                });
                segRes.text().then(text => {
                    // we receive every segment in the body
                    const body = JSON.parse(text);
                    console.log("Received body:", body);
                    setBody(body);
                });
            } catch (e) {
                console.log("error when fetching from db", e);
            }
        }
        getSegments();
    } ,[]);

    useReplaceStore({segmentsArray: body});

    // fetch every segment stored in the reduxjs db
    const mySegments : SegmentInfo[] = useSelector((state: any) => state.segments.segmentsInfos.segmentsArray);

    // some maths
    // we are building triangles bigger than the wheel so we only see the base of
    // the triangle and it looks like a segment thanks to the overflow hidden attribute
    // the triangles have 2 equal sides and we want an angle of PI / nbSegments
    // so we can calculate the length of the base with trigo

    const triangleHeight = 60; // the height in % in the CSS
    const offset = Math.tan(Math.PI / mySegments.length / 2) * triangleHeight;

    const leftX : number = 50 - offset;    
    const rightX : number = 50 + offset;    

    // we create a segment for each item found in the config file
    const segments: JSX.Element[] = [];
    // we do it twice parce que c'est plus joli
    for(let i = 0; i < 2; i++) {
        mySegments.forEach(async (seg: SegmentInfo, index: number) => {
            const segmentId = i * mySegments.length + index;
            // api call to get the image
            segments.push(
                <div key={segmentId} className={"segment " + segmentId.toString()} style={genSegment(
                    segmentId,
                    seg.color,
                    mySegments.length * 2,
                    leftX,
                    rightX)}>
                    <img src={"https://rdlfbingo.dev.mtv-nprd.cloud.bpifrance.fr/images/" + seg.picto} alt={"Example " + segmentId.toString()} draggable='false' style={{ maxWidth: '30%', maxHeight: '40%', width: 'auto', height: 'auto' }} />
                    {seg.name}
                </div>
            );
        });
    }

    return (
        <>
            {segments}
        </>
    )
}

export default Segment;