import {createSlice} from '@reduxjs/toolkit'

// initial items of the wheel

// const names = ["Bonbon", "Stickers", "Affiche", "Tshirt"];
// const pictos = ["bonbon.png", "stickers.png", "affiche.png", "tshirt.png"];
// const colors = ["#e84447", "#fdc800", "#ec6b1f", "#e61f67"];

const segmentsSlice = createSlice({
    name: "segments",
    initialState: {
        segmentsInfos: {
            segmentsArray: [{
                color: "#e84447",
                name: "Bonbon"
            }, {
                color: "#fdc800",
                name: "Stickers"
            }, {
                color: "#ec6b1f",
                name: "Affiche"
            }, {
                color: "#e61f67",
                name: "Tshirt"
            }]
        }
    },
    reducers: {
        update: (state, action) => {
            state.segmentsInfos = action.payload;
        }
    }
});

export {segmentsSlice}