import { SegmentInfo } from "./Type/SegmentInfo";

// this form ask for the three main components of a segment

function SegmentForm(key : number, infos? : SegmentInfo) {
    if (infos) {
        return (
            <div className="formContainer" key={key} id={"formContainer"+key.toString}>
                <input defaultValue={infos.name} className="formName" type="text" id={"name_input"+key} placeholder="Ballon / bonbon / Tshirt ..." />
                <div className="firstRow">
                    <input defaultValue={infos.color} className="formColor" type="color" id={"color_input" + key} />
                    <input className="formPicto" type="file" id={"picto_input" + key} />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="formContainer" key={key} id={"formContainer"+key.toString}>
                <input className="formName" type="text" id={"name_input"+key} placeholder="Ballon / bonbon / Tshirt ..." />
                <div className="firstRow">
                    <input className="formColor" type="color" id={"color_input" + key} />
                    <input className="formPicto" type="file" id={"picto_input" + key} />
                </div>
            </div>
        )
    }
}

export default SegmentForm;