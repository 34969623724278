import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { segmentsSlice } from "./segmentsSlice";

export const {update} = segmentsSlice.actions;

const rootReducer = combineReducers({
    segments: segmentsSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer
});