import { useState } from 'react';
import './AppBack.css';
import SegmentForm from './SegmentForm';
import { SegmentInfo } from './Type/SegmentInfo';

const backendURL = "https://mtv.internal.dev.eks-nprd.cloud.bpifrance.fr/backend";

async function sendSegments(newSegments: SegmentInfo[]) {
  try {
    await fetch(backendURL + "/updateSegments", {
      method: "POST",
      headers: {
        'Content-type': "application/JSON",
      },
      body: JSON.stringify(newSegments)
    });
    alert('La roue a correctement été modifiée');
  } catch (e) {
    alert('Une erreur s\'est produite lors de l\'envoi des segments, veuillez patienter et réessayer');
    console.log('error trying to send segments:', e);
  }
}

async function getSegments(segments: JSX.Element[]): Promise<SegmentInfo[]> {
  const res = [] as SegmentInfo[];

  const segmentPromises = segments.map((el) => {
    return new Promise<void>((resolve) => {
      const id = el.key;
      const nameInput = document.getElementById("name_input" + id) as HTMLInputElement;
      const colorInput = document.getElementById("color_input" + id) as HTMLInputElement;
      const pictoInput = document.getElementById("picto_input" + id) as HTMLInputElement;
      const file = pictoInput.files![0];

      if (file != null) {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64String = (event.target as FileReader).result as string;
          // TODO : push the image in a bucket and get the url to replace bonbon.png
          res.push({
            name: nameInput.value.toLowerCase(),
            color: colorInput.value,
            picto: base64String,
            image: file.name
          });
          resolve();
        };
        reader.readAsDataURL(file);
      } else {
        res.push({
          name: nameInput.value.toLowerCase(),
          color: colorInput.value,
          picto: "noChange",
          image: "null"
        });
        resolve();
      }
    });
  });

  await Promise.all(segmentPromises);
  return res;
}

function AppBack() {
  // this list will be displayed and can be updated by fetching datas from the front
  const [segments, setSegments]= useState([SegmentForm(0)] as JSX.Element[]);

  return (
    <>
      <button className="formButton" id='plusRow' onClick={() => {
        setSegments(old => [...old, SegmentForm(old.length)]);
      }}>
        +
      </button>

        {
          segments.map((el, index) => {
            return (
              <div className="segmentFormsContainer" key={index}>
                {el}
                <button className='deleteButton formButton' onClick={() => {
                  setSegments(old => old.filter((_, i) => i !== index));
                }}>
                  Delete
                </button>
              </div>
            )
          })
      }

      <button className="formButton" id='sendButton' onClick={async () => {
        // we send a message of type "backToFront" so the front knows it's an update of his segments
        console.log('Sending segmentInfos');
        getSegments(segments).then((infos) => {
          console.log(infos);
          sendSegments(infos);
        });
      }}>Envoyer</button>

      <button className="formButton" id='refreshButton' onClick={async () => {
        console.log("start refresh");
        // this type of message asks the front for a "sendingSegments" type of request
        try {
          // api call to get the data
          const segRes = await fetch(backendURL + "/getSegments", {
            method: "GET",
            headers: {
              'Content-type': "application/JSON",
            }
          });
          const body: SegmentInfo[] = JSON.parse(await segRes.text());
          console.log(body);
          // turn the returned list into proper segmentForms
          setSegments(body.map((el, index) => {
            return SegmentForm(index, el);
          }));
          console.log("finished refresh");
        } catch (e) {
          console.log("segments request failed:", e);
        }
      }}>
        Refresh
      </button>

      <button className="formButton" id='rasleculbutton' onClick={() => { setSegments([]) }}>
        Reset
      </button>
    </>
  )
}

export default AppBack
